<template>
  <div>
    <div
      v-if="selectedCluster && loaded"
    >
      <b-row class="mt-2">
        <b-col
          md="12"
          :lg="(emmas.length !== 0) ? 6 : 12"
        >
          <alarm-statistic-card :alarm-amount="alarmNumbers" />
        </b-col>
        <b-col @click="scrollToTable">
          <offline-emmas-card
            v-if="emmas.length !== 0"
            :offline-emmas="offlineEmmas.length"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          :md="(localGroups.length <= 5) ? 6 : 12"
        >
          <offline-emmas-time-graph
            v-if="emmas.length !== 0"
            :key="updateKeyTimeChartSingle"
            :payload="datasetTimeChartSingleData"
          />
          <div v-else>
            <statistic-card-horizontal
              icon="AlertTriangleIcon"
              color="danger"
              :statistic="$t('houseNoEmmas')"
            />
          </div>
        </b-col>
        <b-col
          sm="12"
          :md="(localGroups.length <= 5) ? 6 : 12"
        >
          <offline-emmas-bar-graph
            v-if="barChartLocalGroupData.length !== 0"
            :key="updateKey"
            :payload="barChartLocalGroupData"
            scope="cluster"
            :title="$t('offlineEmmasPerFlat')"
          />
          <div v-else>
            <statistic-card-horizontal
              icon="AlertTriangleIcon"
              color="danger"
              :statistic="$t('houseNoFlats')"
            />
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="emmas.length !== 0"
      >
        <b-col
          lg="4"
          cols="12"
        >
          <active-network-type-graph
            :emmas="emmas"
            :online-emmas="onlineEmmas"
            :offline-emmas="offlineEmmas"
          />
        </b-col>
        <b-col
          lg="8"
          cols="12"
        >
          <offline-emmas-time-graph
            v-if="datasetTimeChartMultipleData.length !== 0"
            :key="updateKeyTimeChartMultiple"
            :payload="datasetTimeChartMultipleData"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="$store.state.emmas.emmas.length !== 0"
      >
        <b-col
          ref="emma-table"
        >
          <emma-table-good-table
            :emmas="emmas"
            :show-local-groups="true"
          />
        </b-col>
      </b-row>
      <!--      <b-row>-->
      <!--        <b-col>-->
      <!--          <calendar-->
      <!--            :scope="{type: 'cluster', scopeId: selectedCluster.id}"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--      </b-row>-->
    </div>
    <div v-else>
      <selection-cards
        :options="$store.state.clusters.clusters"
        scope="cluster"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import SelectionCards from '@/components/management/SelectionCards.vue'
import OfflineEmmasCard from '@/components/management/cards/OfflineEmmasCard.vue'
import OfflineEmmasTimeGraph from '@/components/management/graphs/OfflineEmmasTimeGraph.vue'
import OfflineEmmasBarGraph from '@/components/management/graphs/OfflineEmmasBarGraph.vue'
import EmmaTableGoodTable from '@/components/management/tables/EmmaTableGoodTable.vue'
import AlarmStatisticCard from '@/components/management/cards/AlarmStatisticCard.vue'
import statisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ActiveNetworkTypeGraph from '@/components/management/graphs/ActiveNetworkTypeGraph.vue'

export default {
  name: 'ClusterDashboard',
  components: {
    BRow,
    BCol,
    EmmaTableGoodTable,
    OfflineEmmasCard,
    OfflineEmmasTimeGraph,
    OfflineEmmasBarGraph,
    AlarmStatisticCard,
    SelectionCards,
    statisticCardHorizontal,
    ActiveNetworkTypeGraph,
  },
  data() {
    return {
      updateKey: 0,
      currentTimestamp: Date.now(),
      updateKeyTimeChartSingle: 0,
      updateKeyTimeChartMultiple: 0,
    }
  },
  computed: {
    loaded() {
      if (this.datasetTimeChartSingleData.length !== 0) {
        return true
      }
      return false
    },
    selectedCluster() {
      return this.$store.getters['clusters/getClusterById'](parseInt(this.$route.query.id, 10))
    },
    localGroups() {
      if (this.selectedCluster) {
        return this.$store.getters['localGroups/getLocalGroupsByClusterId'](this.selectedCluster.id, 10)
      }
      return []
    },
    emmas() {
      if (this.selectedCluster) {
        return this.$store.getters['emmas/getEmmasByClusterId'](this.selectedCluster.id, 10)
      }
      return []
    },
    offlineEmmas() {
      return this.emmas.filter(emma => this.getTimeSinceHeartbeat(emma.timestamp) > this.$store.state.emmas.secondsBeforeOffline || emma.timestamp === undefined)
    },
    onlineEmmas() {
      return this.emmas.filter(emma => this.getTimeSinceHeartbeat(emma.timestamp) <= this.$store.state.emmas.secondsBeforeOffline && emma.timestamp !== undefined)
    },
    barChartLocalGroupData() {
      const dataset = []
      for (let i = 0; i < this.localGroups.length; i += 1) {
        const localGroupEmmas = this.$store.getters['emmas/getEmmasByLocalGroupId'](this.localGroups[i].id)
        const localGroupEmmasOffline = localGroupEmmas.filter(emma => this.getTimeSinceHeartbeat(emma.timestamp) > this.$store.state.emmas.secondsBeforeOffline || emma.timestamp === undefined)
        dataset.push({
          name: this.localGroups[i].name,
          dataset: localGroupEmmasOffline.length,
          totalEmmas: localGroupEmmas.length,
        })
      }
      return dataset
    },
    alarmNumbers() {
      try {
        return Number.parseInt(this.selectedCluster.alarmHistory, 10)
      } catch (e) {
        return 0
      }
    },
    datasetTimeChartSingleData() {
      if (this.selectedCluster) {
        return [{ name: this.selectedCluster.name, dataset: this.selectedCluster.offlineEmmaHistory, totalEmmas: this.emmas.length }]
      }
      return []
    },
    datasetTimeChartMultipleData() {
      const dataset = []
      for (let i = 0; i < this.localGroups.length; i += 1) {
        dataset.push(
          {
            name: this.localGroups[i].name,
            dataset: this.localGroups[i].offlineEmmaHistory,
            totalEmmas: this.$store.getters['emmas/getEmmasByLocalGroupId'](this.localGroups[i].id).length,
          },
        )
      }
      return dataset
    },
  },
  watch: {
    barChartLocalGroupData(oldVal, newVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateKey += 1
      }
    },
    datasetTimeChartSingleData(oldVal, newVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateKeyTimeChartSingle += 1
      }
    },
    datasetTimeChartMultipleData(oldVal, newVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateKeyTimeChartMultiple += 1
      }
    },
  },
  async mounted() {
    if (this.$store.state.clusters.clusters.length === 1 && !this.$route.query.id) {
      await this.$router.push(`/dashboard/cluster?id=${this.$store.state.clusters.clusters[0].id}`)
    }
    this.setPageTitle()
  },
  updated() {
    if (this.selectedCluster !== undefined) {
      console.log('fetching cluster appointments') // TODO: make real
    }
    this.setPageTitle()
  },
  methods: {
    setPageTitle() {
      if (this.selectedCluster !== undefined) {
        this.$store.commit('app/SET_PAGE_TITLE', this.selectedCluster.name)
      } else {
        this.$store.commit('app/SET_PAGE_TITLE', this.$t('houses'))
      }
    },
    scrollToTable() {
      this.$refs['emma-table'].scrollIntoView({ behavior: 'smooth' })
    },
    async setUpdateKey() {
      this.currentTimestamp = Date.now()
    },
    getTimeSinceHeartbeat(heartbeat) {
      const differenceMilliseconds = this.currentTimestamp - heartbeat
      const differenceSeconds = Math.floor(differenceMilliseconds / 1000)
      return differenceSeconds
    },
  },
}
</script>

<style scoped>

</style>
